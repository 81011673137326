<template>
  <div>
    <div class="log-main">
      <div class="login">
        <img class="left" src="../../assets/images/login_left.png"/>
        <div class="right">
          <div class="login-form">
            <div class="title">登录寻疗管理后台</div>
            <div class="title-eng">Log in to seek treatment management background</div>
            <div class="form">
              <form @keyup.enter="goLogin" @submit.prevent>
                <div class="form-input">
                  <i class="ri-lg i-font" name="bussiness-man"></i>
                  <p class="form-holder" :class="{active: isActive}">请输入登录账号</p>
                  <input type="text" v-model="username" @blur="bulr" @focus="focus"/>
                </div>
                <div class="form-input">
                  <i class="ri-lg i-font" name="key-line"></i>
                  <p class="form-holder" :class="{active: isActivePass}">请输入登录密码</p>
                  <input type="password" v-model="password" @focus="focusPass" @blur="bulrPass"/>
                </div>
                <Button @click.native.stop="goLogin" class="form-btn">立即登录</Button>
                <div class="lc-tips">
                  建议使用
                  <a href="https://wxapp.cqkundian.com/ChromeSetup.exe" target="_blank"
                     title="立即下载">谷歌浏览器</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-version">
        <span>
          <p> 寻疗管理 &nbsp; Copyright ©寻疗团队 &nbsp;&nbsp;</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      isActive: false,
      isActivePass: false
    }
  },
  methods: {
    goLogin() {
      var _this = this;
      if (!this.username) return this.alertErr('登录名不能为空！');
      if (!this.password) return this.alertErr('登录密码不能为空！');
      var pwd = JSON.parse(JSON.stringify(this.password));
      pwd = md5(pwd).substr(2, 28);
      this.requestApi('/adm/login', {
        uname: this.username,
        pwd: pwd
      }).then(function (res) {
        if (res.status) {
          localStorage.setItem('store_token', res.token.token);
          localStorage.setItem('store_out_time', res.token.out_time);
          localStorage.setItem('total_admin_info', res.admin);
          setTimeout(function () {
            _this.toUrl('/');
          }, 300);
          _this.alertSucc(res.msg);
        } else {
          _this.alertErr(res.msg)
        }
      }).catch(function (error) {
        console.log(error)
      })
    },
    focus: function () {
      this.isActive = true
    },
    bulr: function (e) {
      if (e.target.value) {
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    focusPass: function () {
      this.isActivePass = true
    },
    bulrPass: function (e) {
      if (e.target.value) {
        this.isActivePass = true
      } else {
        this.isActivePass = false
      }
    }
  }
}
</script>

<style>
.log-main {
  background-image: url(../../assets/images/login_1.jpg);
  background-size: 100%;
  height: 100%;
  margin: 0px auto;
  position: fixed;
  width: 100%
}

.login {
  width: 1000px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.login .left {
  width: 500px;
  height: 500px;
}

.login .right {
  width: 500px;
  height: 500px;
  background-color: #fff;
  border-radius: 0px 12px 12px 0px;
}

.login-form {

  padding-top: 32px;
}

.login-form .title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #1890ff;
  font-weight: 700;
}

.login-form .title-eng {
  text-align: center;
  color: #1890ff;
  font-size: 12px;
}

.login-form .form {
  width: 300px;
  margin-left: 100px;
  margin-top: 48px;
}

.login-form .form .form-input {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 2px solid;
  padding: 0 12px;
  margin-bottom: 36px;
  border-bottom-color: rgba(74, 158, 244, .6);
}

.login-form .form .form-input .i-font {
  color: #4a9ef4;
}

.ri-lg {
  font-size: 0.5em;
  line-height: 0.3em;
  vertical-align: -.0667em;
}

.login-form .form .form-input .form-holder {
  margin-left: 12px;
  font-size: 18px;
  color: #999;
  margin-top: 14px;
  transition: .2s linear;
}

.login-form .form .form-input .active {
  font-size: 12px;
  margin-top: -48px;

}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

.login-form .form .form-input input {
  width: 250px;
  height: 40px;
  margin-left: 12px;
  border: none;
  outline: none;
  font-size: 18px;
  background: none;
  z-index: 9;
  position: absolute;
}

.login-form .form .form-btn {
  width: 100%;
  height: 48px;
  border-radius: 6px;
  margin-top: 24px;
  border: none;
  background: #1890ff;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  transition: .2s linear;
  outline: none;
}

.go-register {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-size: 12px;
  color: #4a4a4a;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.login .lc-tips {
  font-size: 12px;
  color: #999;
  margin-top: 40px;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}

.login-con .lc-tips a {
  text-decoration: none;
  color: #29adeb;
}

.footer-version {
  width: 100%;
  height: 30px;
  line-height: 30px;
  background: rgba(0, 0, 0, .6);
  position: fixed;
  bottom: 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
}
</style>
